$env: production;
@import '@/styles/_component-base.styles.scss';

.nav {
    background-color: color(contrast, 1);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    font-weight: normal;
    height: 60px;
    padding: spacer(xs);
    position: relative;
    top: 0;
    transition: transform 300ms linear;
    width: 100%;
    z-index: 999;

    @include breakpoint(md) {
        padding: 0;
    }

    @include breakpoint(lg) {
        justify-content: unset;
        align-items: unset;
        height: 80px;
        padding: 0 0 spacer(sm);
    }

    &.navSticky {
        position: fixed;
        background-color: color(contrast, 1);
    }
}

.lowerNav {
    align-items: left;
    background-color: color(contrast, 1);
    bottom: 0;
    display: none;
    flex-direction: column;
    font-weight: normal;
    justify-content: flex-start;
    padding: spacer(sm) spacer(md);
    position: fixed;
    width: 100%;
    z-index: 10;

    @include breakpoint(md) {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }

    &.lowerNavSticky {
        display: flex;
    }
}

.linkSet {
    align-items: center;
    background-color: color(contrast, 1);
    display: flex;
    font-weight: normal;
    justify-content: space-between;
    overflow-y: visible;
    padding: 0;
    top: 0;
    width: 100%;

    @include breakpoint(md) {
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        padding: spacer(sm) 0;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;
        &::-webkit-scrollbar { display: none; }
    }

    @include breakpoint(lg) {
        height: 80px;
    }

    &.linkSetSpaceAdjusted {
        justify-content: center;
        @include breakpoint(lg) {
            justify-content: space-between;
        }
    }
}

.strikethroughPrice {
    color: color(contrast, 3);
    font-family: $fontFamily-serif;
    text-decoration: line-through;
}

.priceContainer {
    align-items: center;
    display: flex;
    font-family: $fontFamily-serif;
    font-size: set-fontSize(20);
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    padding-left: 0;

    @include breakpoint(xl) {
        padding-left: spacer(base);
        padding-right: spacer(base);
    }
}

.shopNowBtn {
    border-radius: 3px;
    font-family: $fontFamily-serif;

    &.shopNowBtnFadeIn {
        display: block;
        transition: all ease .3s;
    }

    &.blue {
        background: color(accent, blue);
        border-color: color(accent, blue);

        &:hover,
        &:active,
        &:focus {
            background: #5c8296;
            border-color: #5c8296;
        }
    }

    &.green {
        background: color(accent, green);
    }
}

.linkWrapper {
    display: flex;
    width: 100%;

    @include breakpoint(lg) {
        width: 50%;
    }

    ul {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }
}

.link {
    border: none;
    color: color(text, base);
    cursor: pointer;
    font-family: $fontFamily-serif;
    font-size: set-fontSize(14);
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    padding: spacer(xs) spacer(2xs);
    text-align: center;
    text-transform: capitalize;

    &:hover {
        @include breakpoint(sm) {
            &:after {
                content: '';
                background-color: color(contrast, 4);
                bottom: -8px;
                height: 3px;
                left: -2px;
                position: absolute;
                width: 100%;
            }
        }
    }

    @include breakpoint(sm) {
        font-size: set-fontSize(16);
    }

    @include breakpoint(md) {
        width: 108px;
    }

    @include breakpoint(lg) {
        width: 80px;
    }

    &.active {
        .linkName {
            color: color(text, base);
        }
        &:after {
            content: '';
            background-color: color(accent, blue);
            bottom: 0;
            height: 3px;
            left: -2px;
            position: absolute;
            width: 100%;

            @include breakpoint(sm) {
                bottom: -8px;
            }
        }
    }

    &.linkSpaceAdjusted {
        width: 80px;
    }
}

.productInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(md) {
        flex-direction: row;
    }

    @include breakpoint(lg) {
        flex-direction: column;
        width: 210px;
        padding: spacer(xs);
    }
}

.productInfoLabel {
    white-space: nowrap;
    font-size: set-fontSize(20);
    font-family: $fontFamily-serif;
    text-overflow: ellipsis;
    overflow: hidden;
}

.productInfoText {
    display: flex;
    justify-content: space-between;
}

.productInfoDetails {
    display: flex;
    font-family: $fontFamily-serif;
    flex-direction: row;
    gap: 6px;
    padding-top: spacer(xs);

    @include breakpoint(md) {
        padding-top: 0;
    }

    button {
        width: 100%;
    }
}

/* Sets height to avoid layout shift on mobile, resets it back to 0 on desktop */
.navBar {
    height: 60px;

    @include breakpoint(lg) {
        height: 0;
        display: none;
    }
}

@keyframes fadeInAndUp{from{opacity: 0; transform: translateY(6px);} to{opacity: 1; transform: translateY(0);}}

.fadeInAndUpAnimation {
    animation: fadeInAndUp 0.3s ease-out;
}
