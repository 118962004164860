$env: production;
@import '@/styles/_component-base.styles.scss';

.header {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    gap: spacer(2xs);
}

.titleContainer {
    color: color(text);
    font-size: set-fontSize(24);
    margin-bottom: 0;
    width: 100%;
    line-height: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: spacer(sm);

    @include breakpoint(md) {
        font-size: set-fontSize(28);
        padding-bottom: spacer(xs);
    }
}

.adjustableBasePlus {
    font-style: italic;
    color: color(accent, base);
    font-synthesis: initial; // overrides Safari display fix
}

// Exact measurements of the viewbox and icon
.awardWinningIcon {
    height: 28px;
    width: 74px;
}
