$env: production;
@import '@/styles/_component-base.styles.scss';

.detailedCheckboxBuystackAddon {
    display: flex;
    flex-direction: column;
    margin-bottom: spacer(lg);
    gap: spacer(xs);
}

.detailedCheckboxBuystackAddon__productIntro {
    display: flex;
    text-align: left;
    flex-direction: column;
    line-height: spacer(lg);
}

.detailedCheckboxBuystackAddon__productIntro__header {
    text-transform: uppercase;
    margin-bottom: spacer(2xs);
    font-weight: normal;
    letter-spacing: 1px;
}

.detailedCheckboxBuystackAddon__productIntro__description {
    font-size: set-fontSize(14);
}

.detailedCheckboxBuystackAddon__productContentContainer {
    display: flex;
    gap: spacer(xs);
    padding: spacer(xs);
    overflow: hidden;
    background-color: color(contrast, 1);
    border: 1px solid color(contrast, 3);
    transition: height 300ms ease-out;

    &.active {
        background-color: color(contrast, 2);
        border: 1px solid color(darkContrast, 3);
    }
}

.detailedCheckboxBuystackAddon__productContentContainer__left {
    display: block;
    flex-shrink: 0;
    width: 100px;

    @include breakpoint(lg) {
        width: 80px;
    }

    @include breakpoint(xl) {
        width: 100px;
    }

    &__productImage {
        width: 100%;
    }

    &__badge {
        border-top-left-radius: 0;
    }
}

.detailedCheckboxBuystackAddon__productContentContainer__right {
    display: flex;
    flex-direction: column;
    gap: spacer(2xs);
    flex-grow: 1;
}

.detailedCheckboxBuystackAddon__productContentContainer__right__checkbox {
    display: block;
    input:checked+label::before {
        background-color: color(action, base);
    }

    input:focus+label::before {
        border: 2px solid color(action, base);
    }
}

.detailedCheckboxBuystackAddon__productContentContainer__right__checkbox,
.formCheckbox__label {
    label {
        padding: 0 spacer(md) 0 0;
        text-align: left;

        &:before {
            border: 1px solid color(accent, base);
            top: 4px;
            left: auto;
            right: 0;
            width: spacer(base);
            height: spacer(base);
        }
    
    
        &:after {
            top: calc((1rem) / 2);
            left: auto;
            right: calc((1rem - 11px) / 2);
            width: 11px;
            height: 6px;
        }
    }


    a {
        color: color(text, headingDark);
    }

    span {
        .detailedCheckboxBuystackAddon__checkbox__label {
            &--price {
                display: block;
            }

            &--finalPrice {
                display: inline-flex;
                font-weight: 400;
            }

            &--strikethroughPrice {
                color: color(text, recede);
                font-weight: 300;
                text-decoration: line-through;
                margin-left: spacer(2xs);
            }

            &--valueTag {
                display: inline;
                color: white;
                background-color: color(accent, green);
                font-size: set-fontSize(12);
                padding: spacer(3xs) spacer(2xs);
                margin: spacer(3xs);

                @include breakpoint(xl) {
                    display: block;
                    width: fit-content;
                }
            }
        }
    }
}

.detailedCheckboxBuystackAddon__productContentContainer__right__description {
    text-align: left;
}

.detailedCheckboxBuystackAddon__productContentContainer__right__productSwatch {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacer(2xs);
}
