$env: production;
@import '@/styles/_component-base.styles.scss';

.discountLabels {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;

    &--amount {
        background-color: color(contrast, 1);
        flex-direction: column;
        padding: spacer(base);
    }
}