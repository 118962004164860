$env: production;
@import '@/styles/_component-base.styles.scss';

.formRadio {
    display: flex;
    justify-content: space-between;
    gap: spacer(xs);
}

.toggle {
    min-height: 64px;
    align-items: center;
    font-size: spacer(base);
    color: color(text, base);
    background-color: color(contrast, 1);
    border-color: color(contrast, 3);
    padding: spacer(xs);
    

    @include breakpoint(md) {
        min-height: auto;
        padding: spacer(sm) spacer(xs);
    }

    &:hover {
        color: color(text, base);
        background-color: color(contrast, 2);
        border-color: color(text, headingDark);
        font-weight: 600;
    }

    &.isSelected {
        color: color(text, base);
        background-color: color(contrast, 2);
        border-color: color(darkContrast, 2);
        font-weight: 600;
    }
}

.title {
    margin-bottom: spacer(xs);
    line-height: 20px;
    font-weight: normal;
    letter-spacing: 0.25px;
    font-size: calc(14 / 16 * 1rem);
    display: block;
    text-align: left;
}
