$env: production;
@import '@/styles/_component-base.styles.scss';

.subtotal__affirmMessaging {
    font-size: set-fontSize(14);
    font-family: $fontFamily-sans-serif;

        span:nth-child(-n+2) {
        font-weight: 700;
        font-size: set-fontSize(16);
    }
}