$env: production;
@import '@/styles/_component-base.styles.scss';

.title {
    margin-bottom: spacer(xl);
    
    @include only-breakpoint(xs){
        max-width: 295px;
        margin-left: auto;
        margin-right: auto;
    }
    
    @include breakpoint(md) {
        margin-bottom: spacer(md);
    }

    @include breakpoint(lg) {
        margin-bottom: spacer(xl);
    }
}

.buttons {
    @include breakpoint(sm, max) {
        padding: spacer(xs) spacer(sm) spacer(2xs);
        border-radius: 40px;
        background-color: rgba(255, 255, 255, 0.55);
        top: 37%
    }

    @include breakpoint(md) {
        top: 29%
    }

    @include breakpoint(lg) {
        top: 39%
    }
}

.leftButton {
    z-index: 1;
}

.wrapper {
    @include breakpoint(md) {
        margin: 0 spacer(sm) * -1;
    }
}

.compactWrapper {
    display: flex;
    flex: 1 0;
}

.defaultSlider {
    display: none;
    @include breakpoint(md) {
        display: block;
    }
}

.mobileSlider {
    display: block;

    @include breakpoint(md) {
        display: none;
    }
}

.tile {  
    padding: 0 spacer(2xs);
    max-width: 100%;

    
    @include breakpoint(md) {
        max-width: none;
        min-height: 280px;
    }

    @include breakpoint(lg) {
        padding: 0 spacer(sm);
        min-height: 385px;
    }
}

.compactTile {
    padding: 0;
    max-width: 50%;

    &:first-child {
        margin-right: spacer(sm);

        @include breakpoint(lg) {
            margin-right: spacer(2xs);
        }
    }

    &:last-child {
        margin-left: spacer(sm);

        @include breakpoint(lg) {
            margin-left: spacer(2xs);
        }
    }

    /* This applies to the link button inside the tile and overrides 200px min-width */
    a {
        min-width: 50%;

        &[data-selector="productTileTitleLink"] {
            margin-top: spacer(sm);
        }

        &[target="_self"] {
            margin-top: spacer(base);
        }
    }

    /** Targeting via data attribute instead of creating a bit update.
      * Worst case, if the selector were to change, this element
      * would have extra margin, but function identically. */
    div[data-selector="fullPrice"] {
        /* Display prices on two lines */
        margin-left: 0;
        margin-top: spacer(xs);

        /* Add spacing from larger mobile through tablet breakpoints */
        @include breakpoint(490px) {
            margin-left: spacer(2xs);
            margin-top: 0;
        }

        /* Revert to two lines on small desktop or oversized tablet screens */
        @include breakpoint(lg) {
            margin-left: 0;
            margin-top: spacer(xs);
        }

        /* Back to a single line on large desktops */
        @include breakpoint(xl) {
            margin-left: spacer(2xs);
            margin-top: 0;
        }
    }
}
