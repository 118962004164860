$env: production;
@import '@/styles/_component-base.styles.scss';

.productLongDescription {
    font-size: set-fontSize(14);
    margin-top: spacer(xs);
    text-align: left;
    // Current font family doesn't include italic variant
    // Allow browser to synthesize an italic variant
    font-synthesis: initial;

    ul {
        padding-left: spacer(base);
        list-style-type: disc;
        line-height: 2.1;

        &:last-child {
            margin-bottom: spacer(sm);
        }
    }

    p {
        margin-bottom: spacer(base);
    }

    // Can't receive t-link class from Prismic Rich Text, style the anchor tags directly
    a {
        color: inherit;
        font: inherit;
        text-decoration: underline;
        cursor: pointer;

        &:hover,
        &:focus {
            outline: none;
            color: color(text, hover);
        }
    }

    em {
        font-style: italic;
    }

    strong {
        font-weight: bold;
    }
}
