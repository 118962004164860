$env: production;
@import '@/styles/_component-base.styles.scss';

.ribbon {
    text-transform: uppercase;
    width: fit-content;
    padding: 0 spacer(sm);

    color: color(contrast, 1);
    background-color: color(darkContrast, 1);

    --ribbon-inset: 3px;
    clip-path: polygon(0 0,
        100% 0,
        calc(100% - var(--ribbon-inset)) 50%,
        100% 100%,
        0 100%,
        var(--ribbon-inset) 50%);
}
