$env: production;
@import '@/styles/_component-base.styles.scss';

.productCarouselGalleryHeroImage {
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}
.productCarouselGalleryHeroImageZoomTag {
    font-weight: 400;
    font-size: 1em;
    border-radius: spacer(md);
    margin-left: auto;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.2em 0.875em;
    color: color(contrast, white);

    svg {
        margin-left: 6px;
        top: 3px;
    }
}

.productCarouselGalleryPicture {
    overflow: hidden;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        height: 100%;
    }
}


// For the carousel redesign test, which has slightly different structure
.productCarouselGalleryHeroImageZoomTagRedesign {
    font-weight: 400;
    font-size: set-fontSize(16);
    border-radius: spacer(md);
    margin-left: auto;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.2em 0.875em;
    color: color(contrast, white);
    position: absolute;
    top: spacer(lg);
    right: spacer(lg);
    z-index: 10;
    pointer-events: none; // used to make the element not clickable/hoverable, preventing flashing when hovering over the button

    svg {
        margin-left: 6px;
        top: 3px;
        height: 16px;
        width: 16px;
    }
}
.imageGallery__video-buttons {
    div {
        top: 200px;

        @include breakpoint(md) {
            top: 450px;
        }

        @include breakpoint(lg) {
            top: 300px;
        }

        @include breakpoint(xl) {
            top: 350px;
        }
    }
}

.pdpCarouselThumbnailsImage {
    aspect-ratio: 4/3;
    display: block;
    height: 100%;
    max-width: 512px;
    width: 100%;
}

.pdpCarouselThumbnailVideo {
    & img {
        margin-top: -(spacer(3xs));
        margin-bottom: -(spacer(xs)); // fix for extra margin that doesn't seem to have a cause
    }
}

.pdpCarouselThumbnailVideoOverlay {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 25%; // allows for vertical centering
    left: 25%; // allows for horizontal centering
    color: color(contrast, white);
}

.pdpCarouselAssetsPicture {
    max-width: 100%;
    height: 100%;
    aspect-ratio: 4/3;
}

.pdpCarouselAssetsPicture img, .pdpCarouselAssetsPicture src {
    display: block;
    max-width: 512px;
    aspect-ratio: 4/3;
    width: 100%;
    height: 100%;
    margin-bottom: spacer(xs);
}
