$env: production;
@import '@/styles/_component-base.styles.scss';

.bbbReviews__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: spacer(2dot5xl);
    padding: 0; // overriding global .container for use as child of MarketSurveyResults
}

.bbbReviews__wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex: 1 1 auto;
    border-radius: 5px;
    padding: spacer(2xl);
    margin: spacer(2xl) 0;
    font-size: set-fontSize(20);
    background: rgba(245, 246, 246, 0.8);

    @include breakpoint(md) {
        flex-direction: row;
        flex: 1 1 auto;
        flex-wrap: nowrap;
        align-items: center;
        padding: spacer(base);
        margin: 0 0 spacer(2dot5xl);
    }

    @include breakpoint(lg) {
        padding: spacer(lg);
        margin: 0 0 spacer(2dot5xl);
    }
}

.bbbReviews__logo {
    align-self: center;
    width: 44px;
    height: 70px;
    min-width: 38px;
    min-height: 60px;

    @include breakpoint(md) {
        align-self: center;
        margin-left: spacer(lg);
    }

    @include breakpoint(lg) {
        margin-left: spacer(base);
    }
}

.bbbReviews__text {
    line-height: normal;
    font-size: set-fontSize(20);
    padding: 0;
    margin: 0;
    font-family: Source Serif Pro;
    text-align: center;

    @include breakpoint(md) {
        text-align: left;
    }
}

.bbbReviews__text__secondLine {
    margin-top: spacer(base);
}

.bbbReviews__text__semiBold {
    @extend .bbbReviews__text;
    font-weight: 600;
}

.bbbReviews__textWrapper {
    text-align: center;

    @include breakpoint(md) { 
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &::before {
        content: '';
        display: inline-block;
        background-color: #A4A4A4;
        width: calc(100% - spacer(2dot5xl));
        height: 1px;
        margin: spacer(base);
    
        @include breakpoint(md) {
            display: block;
            background-color: #A4A4A4;
            width: 1px;
            height: 124px;
            margin: 0 spacer(2xl);
        }

        @include breakpoint(lg) { 
            margin: 0px spacer(lg);
        }
    }
}
