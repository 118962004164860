$env: production;
@import '@/styles/_component-base.styles.scss';

.bannerBase--green {
    background-color: color(accent, green);
    color: color(contrast, white);
}

.bannerBase--blue {
    background-color: color(accent, blue);
    color: color(contrast, white);
}

.bannerBase--black {
    background-color: color(text, base);
    color: color(contrast, white);
}

.bannerBase--brown {
    background-color: color(darkContrast, 3);
    color: color(contrast, white);
}

.bannerBase--red {
    background-color: color(accent, red);
    color: color(contrast, white);
}

.bannerPDPValueDiscount {
    margin-bottom: spacer(md);
    line-height: 2.1;
    padding-left: spacer(base);
    padding-right: spacer(xs);
    display: flex;
    align-items: center;
    width: 100%;
    background-color: rgba(213, 170, 99, 0.2);
    color: color(text, base);
    font-weight: 300;
    justify-content: center;
    font-size: set-fontSize(14);

    @include breakpoint(lg) {
        padding-left: spacer(xs);
        padding-right: spacer(xs);
        margin-bottom: spacer(lg);
    }

    @include breakpoint(xl) {
        padding-left: spacer(base);
        padding-right: spacer(base);
    }
}

.bannerBasePDP {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: spacer(base) spacer(2xs);

    div {
        font-size: set-fontSize(14);
        display: inline;
        min-height: 100%;
        width: auto;

        span {
            display: inline;
        }
    }
}

.inlineSVG {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}
