$env: production;
@import '@/styles/_component-base.styles.scss';

.label {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.modalToggle {
    letter-spacing: normal;
}

.firmnessWrapper {
    border: 1px solid color(contrast, 3);
    padding: .5rem .75rem;
    background-color: color(contrast, 2);
    cursor: pointer;
}
