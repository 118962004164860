$env: production;
@import '@/styles/_component-base.styles.scss';

.yotpoReviewStars {
    min-height: 49px; // reducing mobile CLS when split across two lines

    @media screen and (min-width: 390px) {
        min-height: 25px; // won't break into two lines unless product review count is 6 digits
    }
}

.yotpoReviewStars .placeholder {
    height: 25px; // reducing CLS
}

.earlyReviewMessage {
    display: flex;
    font-size: set-fontSize(14);
    font-weight: normal;
    height: 25px; // reducing CLS

    button {
        color: color(nav, base);
        font-size: set-fontSize(14);
    }

    button:hover {
        color: color(nav, hover);
        cursor: pointer;
    }
}

.yotpoWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: set-fontSize(16) !important;

    .yotpo .yotpo-bottomline .yotpo-icon-star,
    .yotpo .yotpo-bottomline .yotpo-icon-half-star,
    .yotpo .yotpo-bottomline .yotpo-icon-empty-star {
        margin: 0 2px 0 0 !important;
        font-size: set-fontSize(16);

        @include breakpoint(md) {
            margin: 0 spacer(3xs) 0 !important;
        }
    }
}

.bestReviewedIcon {
    width: 20px;
    height: 20px;
    margin: auto 0 auto 0.25rem;
}

.verifiedReviewsIcon {
    margin: 0;
    width: 16px;
    height: 16px;
    margin: 0 spacer(3xs) !important;

    path {
        fill: color(accent, blue) !important;
    }
}

.yotpoProductReviews {
    margin-left: spacer(2xs);
    display: flex;
    align-items: center;
    gap: spacer(2xs);
    font-size: set-fontSize(14);
    color: color(accent, blue);
    font-weight: 600;
    width: 100%; // mobile default

    @media screen and (min-width: 390px) {
        width: 50%; // won't break into two lines unless product review count is 6 digits
    }
}
