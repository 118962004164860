$env: production;
@import '@/styles/_component-base.styles.scss';

.reviewsAccordion__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include breakpoint(md) {
        flex-direction: row;
        align-items: center;
    }
}

.reviewsAccordion__accordion {
    background-color: color(contrast, 2);
}

.reviewsAccordion__iconContainer {
    align-self: center;
}

.reviewsAccordion__content {
    max-height: fit-content !important;
}
