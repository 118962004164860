$env: production;
@import '@/styles/_component-base.styles.scss';

.wrapper {
    background-color: color(accent, blueLight);
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    width: 100%;
    max-height: max-content;
    margin-bottom: spacer(sm);
    padding: spacer(base);
    gap: spacer(base);
}

.message {
    place-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacer(2xs);
}

.text {
    text-align: center;
    margin-bottom: 0;
}

.button {
    font-size: set-fontSize(14);
    border-color: color(accent, blue);
    background-color: color(accent, blue);

    &:active, &:focus, &:hover {
        background-color: lighten(color(accent, blue), 20%);
        border-color: lighten(color(accent, blue), 20%);
    }
}

.inlineForm {
    display: flex;
    flex-direction: column;
    gap: spacer(base);

    // These need to be either-or due to how bit component applies classes to sub-elements (ie: `__label`)
    .checkbox, .checkbox--checked {
        text-align: left;
        line-height: spacer(md);

        // The Bit form components apply helper classes to sub-elements of the provided class (ie: `__label`).
        // Since the provided class is a CSS module class, we need to use the `:global` mixin
        // to accurately target it:
        // - this way we hit the desired `MyComponent_checkbox_123lkj__label`
        // - rather than `MyComponent_checkbox__label_123lkj`
        &:global(__label) {
            padding-left: spacer(2xl);

            &::before {
                top: 0;
                width: spacer(md);
                height: spacer(md);
            }

            &::after {
                top: spacer(2xs);
                left: spacer(2xs);
            }
        }
    }

    .checkbox--checked {
        &:global(__label)::before {
            background-color: color(accent, blue) !important;
        }
    }

    .textInput {
        border: 1px solid;
        padding-left: spacer(base);
        background-color: color(contrast, white);
        border-color: color(contrast, 3);

        &:global(.has-focus) {
            border-color: color(accent, blue);
        }

        &::after {
            background-color: unset !important;
        }

        &__label {
            margin-left: spacer(base);
            color: color(text, hover);
        }

        // Override the default error colors, as they are a bit aggressive for a voluntary inline form:

        // This is for the user input text
        :global(.has-error) {
            color: color(text, base);
        }

        // This is for the "Enter email..." label text
        &:global(.has-error) {
            .textInput__label {
                color: color(text, hover);
            }
        }
    }

    .termsLinks {
        @extend .t-bodySm;
        text-align: left;
        margin: -(spacer(xs)) 0 0 0;
        color: #656565;

        a {
            color: #656565;

            &:hover {
                color: color(text, hover);
            }
        }
    }
}

.inlineSubmittedWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacer(sm);

    p:first-of-type {
        @extend .t-heading2;
        color: color(text, base);
        margin: 0;
    }

    .checkmark {
        width: 32px;
        height: 32px;
        color: color(accent, blue);
    }
}

.errorMessage {
    margin: 0;
    color: color(negative, base);
}