$env: production;
@import '@/styles/_component-base.styles.scss';

.containerBreakout {
    width: 100vw;
    margin: 0 0 spacer(sm) -1.25rem;

    @include breakpoint(sm) {
        margin-left: calc(-1 * ((100vw - 540px) / 2));
        width: 100vw;
    }

    @include breakpoint(md) {
        margin-left: calc(-1 * ((100vw - 680px) / 2));
        width: 100vw;
    }

    @include breakpoint(lg) {
        margin-left: 0;
        width: 100%;
    }
}

ul.thumbnailsWrapper {
    justify-content: left;
    overflow-x: scroll;

    &--no-wrap {
        @include breakpoint(sm) {
            justify-content: left;
        }

        @include breakpoint(lg) {
            overflow-x: hidden;
        }
    }

    &--wrap {
        // This is a fix for the video thumbnail image.
        // The default `display: inline-block` pushes the vertical spacing of the other thumbs.
        button {
            display: block;
        }

        @include breakpoint(lg) {
            display: flex;
            flex-wrap: wrap;
            gap: .7rem;
            overflow-x: hidden;
        }
    }   
}
