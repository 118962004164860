$env: production;
@import '@/styles/_component-base.styles.scss';

$modal-default: (
    closeButtonPosition: spacer(4xl),
    headerHorizontalSpacing: spacer(4xl),
    headerVerticalSpacing: spacer(md),
    headerFontSize: set-fontSize(24),
    headerBackgroundColor: color(contrast, 2),
    horizontalSpacing: spacer(4xl),
    verticalSpacing: spacer(3xl),
    modalBackgroundColor: color(contrast, white),
    overlayColor: color(text,heading),
    overlayOpacity: .8
) !default;

$modal-custom: () !default;

$modal-config: map-merge($modal-default, $modal-custom);

.modal {
    z-index: z('modal');
    position: fixed;
    top: 0;
    left: 0;
    background-color: map-get($modal-config, modalBackgroundColor);
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;

    @include breakpoint(md) {
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: auto;
    }
}

.modalContent {
        max-width: 700px;

        padding: map-get($modal-config, headerHeight) map-get($modal-config, horizontalSpacing) map-get($modal-config, verticalSpacing) map-get($modal-config, horizontalSpacing);
}
.modalContentHasTitle {
    padding-top: map-get($modal-config, verticalSpacing);
}

.modalContentHasFooter {
    overflow-y: scroll;
}
    

.modalRecommendedContent {
        width: 100%;
}

.modalHeader {
    text-align: center;
    background-color: map-get($modal-config, headerBackgroundColor);
    font-size: map-get($modal-config, headerFontSize);
    margin: 0;
    padding: map-get($modal-config, headerVerticalSpacing) map-get($modal-config, headerHorizontalSpacing);
}

.modalDialogClose {
    position: absolute;
    cursor: pointer;
    z-index: z('modal');
    top: calc(#{map-get($modal-config, closeButtonPosition)} / 2);
    right: calc(#{map-get($modal-config, closeButtonPosition)} / 2);
    transform: translate(50%, -50%);
    pointer-events: none;
    width: spacer(md);
    height: spacer(md);
}

.modalDialogCloseIsVisible {
    pointer-events: all;
}
    

.modalOverlay::before {
    content: '';
    position: fixed;
    background-color: map-get($modal-config, overlayColor);
    opacity: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: z(overlay);
    transition: opacity ease-out .5s;
    pointer-events: none;
}

.modalOverlayIsVisible::before {
    opacity: map-get($modal-config, overlayOpacity);
    pointer-events: all;
}

.modalOverlayCart::before {
    background: none;
    backdrop-filter: blur(4px);
}

.modalOverlayCartIsVisible::before {
    opacity: 1;
}

.modalFooter {
    margin-top: auto;
    padding: spacer(md);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.44);
    border: solid 1px #ebebeb;
}
