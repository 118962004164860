$env: production;
@import '@/styles/_component-base.styles.scss';

.whatsIncluded {
    p {
        font-weight: 400;
        margin: 0;
    }

    ul {
        font-size: set-fontSize(14);
        list-style-type: disc;
        padding-left: spacer(base);
        margin: 0;
        line-height: 1.75;
    }
}

.sizeSelector {
    background-color: color(contrast, 2);
    width: 100%;
    margin-top: spacer(base);
    padding: spacer(xs) spacer(base);
    border-left: 1px solid color(darkContrast, 2);
    text-align: left;
}
