$env: production;
@import '@/styles/_component-base.styles.scss';

.infoTooltip {
    width: 100%;
    text-align: left;
    color: color(text, base);
}

.infoTooltipButton {
    @include btn-reset();
    position: absolute;
    right: 0;
    bottom: calc(#{spacer(2xs)} + #{1px});
    cursor: pointer;

    &--accordion {
        right: -20px;
        bottom: -3.5px;
    }

    svg {
        width: 16px;
        height: 16px;
    }
}

.infoTooltipTitle {
    font-weight: bold;
    font-size: set-fontSize(14);
}

.infoTooltipContent {
    padding: spacer(sm) spacer(base);
    box-shadow: 0 spacer(3xs) spacer(2xs) 0 rgba($saatva-grey, 0.4);
    border: solid 1px $saatva-clay-dark;
    background-color: $saatva-white;
    max-width: 96%;
    transition: visibility 0s linear 0s, opacity 300ms;
    position: absolute;
    top: 0;
    right: -12px;
    z-index: z('dropdown');

    &::after,
    &::before {
        bottom: 100%;
        right: spacer(sm);
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &::after {
        border-bottom-color: $saatva-white;
        border-width: spacer(xs);
    }

    &::before {
        border-bottom-color: $saatva-clay-dark;
        border-width: calc(#{spacer(xs)} + #{2px});
        margin-right: calc(-#{spacer(3xs)});
    }

    p {
        font-size: set-fontSize(12);
        margin-top: 0;
        margin-bottom: 0;
    }

    &--left {
        left: 50%;
        transform: translate(-50%, 0);

        @include breakpoint(md) {
            left: -12px;
            transform: translate(0, 0);
        }

        &::after,
        &::before {
            left: spacer(sm);
        }

        &::before {
            margin-left: calc(-#{spacer(3xs)});
        }
    }

    &--right {
        right: 50%;
        transform: translate(-50%, 0);

        @include breakpoint(md) {
            right: -12px;
            transform: translate(0, 0);
        }

        &::after,
        &::before {
            right: spacer(sm);
        }

        &::before {
            margin-right: calc(-#{spacer(3xs)});
        }
    }
}

.infoTooltipContent--accordion {
    right: -32px;
    max-width: none;
    min-width: 140px;
    text-align: center;
    cursor: default;

    @include breakpoint(sm) {
        min-width: 210px;
    }
}

.infoTooltipContent--hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
}

.infoTooltipLink {
    font-size: inherit;
    font-weight: inherit;
    text-decoration: underline;

    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        color: color(text, hover);
    }
}