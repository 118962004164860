$env: production;
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f6f6;
  border-radius: 8px;
  width: 335px;
  margin: 0 auto;
  flex-direction: row;
  margin-top: 60px;
  margin-bottom: 0;

  @media (min-width: 768px) {
    margin-top: 75px;
    width: 446px;
    margin-bottom: 0;
    
  }

  @media (min-width: 1024px) {
    width: auto;
    margin-top: 30px;
    margin-bottom: 0px;
  }
}


.columnLayout {
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 25px;
  margin: 1rem 0rem 1rem 1rem;
  height: 62px;
  width: 62px;
}

.icon {
  font-size: 48px;
  color: #C19678;
}

.textContainer {
  margin: 1.3rem 1rem 1rem 1rem;
  text-align: left;
}

.title {
  font-size: 20px;
  font-family: "Source Serif Pro";
  margin: 0 0 8px;
  color: #333;
  line-height: 1.5;
  text-align: center;

  @media (min-width: 768px) {
    line-height: 1;
    width: 446px;
  }

  @media (min-width: 1024px) {
    line-height: 1;
    width: auto;
  }
}

.contact {
  font-size: 16px;
  margin: 0;
  color: #555;
}

.contactInfo {
  margin-top: 0.7rem;
  font-size: 14px;
  font-family: "Source Serif Pro";
  color: #000;

  @media (min-width: 768px) {
    text-align: center;
  }

  @media (min-width: 1024px) {
    text-align: left;
  }
}

.contactInfo .dot {
  margin: 0 8px;

  @media (min-width: 768px) {
    margin: 0 0.5rem;

  }
}

.link {
  text-decoration: none;
  color: #0074d9;
}

.chatLink {
  cursor: pointer;
  transition: color 0.1s ease-in-out;

  &:hover,
  &:active {
    color: #ccc;
  }
}