$env: production;
@import '@/styles/_component-base.styles.scss';

.checkboxAddon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    padding: spacer(xs) 0;
}

.checkboxAddon__image {
    max-height: 30px;
}

.checkboxAddon__label {
    font-size: set-fontSize(14);

    &Text {
        line-height: 1;
        white-space: nowrap;
        width: 100%;
    }

    &Price {
        font-weight: $fontWeight-light;
    }
}

.checkboxAddon__checkbox {
    .formCheckbox__label::after {
        top: spacer(xs)
    }
}