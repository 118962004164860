$env: production;
@import '@/styles/_component-base.styles.scss';

.video {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    padding-top: spacer(2xl);
    padding-bottom: spacer(base);

    @include breakpoint(lg) {
        padding-top: spacer(base);
    }
}
