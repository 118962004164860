$env: production;
@import '@/styles/_component-base.styles.scss';

.galleryModal {
    display: none;
    padding: 0;
    max-width: 1130px;
    max-height: 100vh;

    @include breakpoint(lg) {
        padding-bottom: spacer(md);
    }

    @include breakpoint(md, max) {
        height: 100vh;
        width: 100vw;
        transform: none;
        top: 0;
        left: 0;
    }
}

.galleryModalIsVisible {
        display: block;
}

.galleryModalOverlay::before {
        background-color: rgba(0, 0, 0, 0.65);
}

.galleryModalCloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
    transform: none;

    @include breakpoint(lg) {
        svg {
            stroke: white;
        }
    }
}

.galleryModalContent {
    display: none;
    width: auto;
    max-width: 1130px !important;
    padding: 0 !important;
}
.galleryModalContentIsVisible {
    display: block;
}


.galleryModalTransform {
    // overrides default styles
    width: 100% !important;
    aspect-ratio: 3/2;

    @media screen and (orientation: landscape) {
        aspect-ratio: 16/9;
    }

    @include breakpoint(md) {
        aspect-ratio: 4/3;

        @media screen and (orientation: landscape) {
            aspect-ratio: 16/9;
        }
    }
    @include breakpoint(lg) {
        height: 100% !important;
        aspect-ratio: 16/9;
    }
}
.galleryModalTransformWrapper {
    @media screen and (orientation: landscape) {
        width: 50% !important;
        margin: 0 auto !important;

        @include breakpoint(lg) {
            width: 100% !important;
        }
    }
}
