$env: production;
@import '@/styles/_component-base.styles.scss';

.deliveryAndSetup {
	max-width: 444px;
	height: 92px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1 0 0;
}

// On desktop, the container shrinks when we add the text paragraph
// in the section, so this forces the correct width and spacing.
.forceWidth {
    @include breakpoint(lg) {
        width: 444px;
    }
}

// Handles the spacing for the cases where there are three items rather than four
.threeItems {
    justify-content: center;
    gap: spacer(3xl);
}

.mobileAndTabletSpacing {
    padding-top: spacer(2xl);
    justify-content: space-between;
}

.section {
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(lg) {
        padding: spacer(base) 0;
    }
}

// Includes overrides for the section class when there is extra text for the PDP
.sectionWithTextAbove {
    flex-direction: column;
    @include breakpoint(lg) {
        padding: spacer(md) 0 spacer(3dot75xl);
    }
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    min-width: spacer(4xl);
    max-width: 100px;
    height: 100%;
    letter-spacing: 0;
    padding: spacer(2xs) 0;
    line-height: spacer(base);
}

.valueProp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: spacer(sm);

    &:hover {
        .valuePropHeading {
            color: color(state, active);
        }
    }
}

.heading {
    max-height: spacer(2xl);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: color(darkContrast, 3);
    font-size: set-fontSize(12);
    margin: 0;
    white-space: break-spaces;
}

.smallHeading {
    width: spacer(3dot75xl);
}
		

.iconWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f8f2ef;
    border-radius: 25px;
    margin: 0;
    height: 36px;
    width: 36px;

    svg, [data-style-svgtype] {
        color: color(action, base);
    }
}

.aboveValuePropsText {
    font-weight: normal;
    color: color(text, headingDark);
    margin-bottom: -(spacer(base));

    @include breakpoint(lg) {
        margin-bottom: spacer(xl);
    }
}
