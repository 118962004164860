$env: production;
@import '@/styles/_move-to-pl-tools.styles.scss';
@import '@/styles/_component-base.styles.scss';

.marketSurveyResults {
    $folder: '/pdp-images';
    $name: 'market-survey';
    $mobileWidth: 480;
    $tabletWidth: 768;
    $desktopWidth: 768;
    $autoOptions: 'format,compress';
    $backgroundImageString: '#{$imgixDomain}#{$folder}/#{$name}/u-#{$name}.jpg?auto=#{$autoOptions}&w=';

    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('#{$backgroundImageString}#{$mobileWidth}&dpr=1');

    @include breakpoint(md) {
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('#{$backgroundImageString}#{$tabletWidth}&dpr=1');
    }

    @include breakpoint(lg) {
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('#{$backgroundImageString}#{$desktopWidth}&dpr=1');
    }
    
    @media screen and (min-resolution: 192dpi) {
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('#{$backgroundImageString}#{$mobileWidth}&dpr=2');
        
        @include breakpoint(md) {
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('#{$backgroundImageString}#{$tabletWidth}&dpr=2');
        }

        @include breakpoint(lg) {
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('#{$backgroundImageString}#{$desktopWidth}&dpr=2');
        }
    }
    background-size: cover;
    background-position: center;

    @include breakpoint(md) {
        background-position: center;
    }
}

.marketSurveyResults__container {
    padding: spacer(2dot5xl) spacer(md) spacer(3xl);

    @include breakpoint(md) {
        padding: spacer(3dot75xl) spacer(md);
    }
}

.marketSurveyResults__header {
    color: color(contrast, white);
    margin-bottom: spacer(2dot5xl);
}

.marketSurveyResults__dots {
    margin-top: spacer(2dot5xl);
    button {
        border: 1px solid white;
        &[aria-current="true"] {
            background: white;
        }
    }
}

.marketSurveyResults__button:disabled {
    display: none;
}

// overriding SimpleSlider component styles
.marketSurveyResults__icon {
    display: inline-block;
    stroke: white !important;
    width: 35px !important;
    height: 21px !important;
}

.marketSurveyResults__iconLeft {
    left: -(spacer(base));
}

.marketSurveyResults__iconRight {
    right: -(spacer(base));
}

.marketSurveyResults__footnote {
    font-size: set-fontSize(10);
    font-weight: 300;
    padding: 0 spacer(xs);
}

.marketSurveyResults__showMoreButton {
    background-color: rgba(0, 0, 0, 0.75) !important;
    border-color: rgba(0, 0, 0, 0.75) !important; // overrides to default component styles
    display: flex;
    margin: 0 auto;
}

.surveyCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    padding: spacer(lg) spacer(base) spacer(base);
    gap: spacer(xs);
    margin-bottom: spacer(sm);
    width: 100%; // relies on padding from parent element
    height: auto;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 5px;

    @include breakpoint(md) {
        width: 202px;
        height: 304px;
        padding: spacer(2xl);
        margin: 0 auto;
        background: rgba(0, 0, 0, 0.5);
    }

    @include breakpoint(xl) {
        width: 326px;
        height: 226px;
        background: rgba(0, 0, 0, 0.7);
    }
}

.surveyCard__heading {
    font-size: set-fontSize(40);
    line-height: 1.25;
    font-weight: 400;
    color: #cfa058; // may want to replace with color(accent, base)
}

.surveyCard__content {
    font-size: set-fontSize(16);
    line-height: 1.625;
    font-weight: 300;
    color: color(contrast, white);
}
