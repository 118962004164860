$env: production;
@import '@/styles/_component-base.styles.scss';

.personalizationPanel {
    width: 100%;
    background-color: color(accent, blueLight);
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: spacer(xs) spacer(sm) spacer(sm) spacer(sm);
    margin-bottom: spacer(sm);
    margin-top: spacer(md);
    text-align: left;

    @include breakpoint(sm) {
        margin-left: auto;
        margin-right: auto;
    }
}

.title {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: spacer(xs);
}

.content {
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 0px;
}

.contentItem {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: baseline;
    margin-bottom: 10px;
}

.contentIcon {
    flex: none;
    margin-right: spacer(xs);
    background-color: color(darkContrast, 1);
    min-height: 16px;
    min-width: 16px;
}

.contentText {
    font-size: 14px;
    align-self: flex-start;
    line-height: 16px;
}
