$env: production;
@import '@/styles/_component-base.styles.scss';

.subtotal {
    display: flex;
    align-items: baseline;
    margin-bottom: spacer(2xs);
    justify-content: space-between;
}

.subtotalLeft {
    display: flex;
    align-items: baseline;
    gap: spacer(2xs);
}

.text {
    font-weight: 400;
    font-size: set-fontSize(24);
    line-height: 32px;
}

.finalPrice {
    font-weight: normal;
    line-height: 32px;
    font-size: set-fontSize(24);
}

.strikethroughPrice {
    font-weight: 300;
    color: color(text, recede);
    font-size: set-fontSize(24);
    line-height: 32px;
    text-decoration: line-through;
}

.badge {
    vertical-align: text-bottom;
    margin-right: spacer(xs); /* fixes text overflowing to new line on very small mobile devices */
    font-size: set-fontSize(12);
    height: 20px;
    @media screen and (min-width: 360px) {
        margin-right: spacer(base);
    }
}

.fontSizeWithPromoPill {
    font-size: set-fontSize(20);
}

.toggleButton {
    padding: spacer(3xs);
    cursor: pointer;
    align-items: flex-end;
    gap: spacer(3xs);
    color: color(text, recede);
    transition: color 0.2s ease;
}

.toggleText {
    font-size: set-fontSize(14);
    color: color(text, headingDark);
    font-weight: 300;
    text-transform: lowercase;
    padding-right: spacer(3xs);
}

.chevron {
    transition: transform 0.3s ease;
    color: black;
    stroke-width: 1.3px;
    stroke-linecap: round; // Round the line endings
    transform: scale(0.7); // Reduce length of the lines
}

.chevronRotated {
    transform: scaleY(-1) scale(0.7); // Flips vertically and reduces the length of the lines
    stroke-width: 1.3px;
    stroke-linecap: round; // Round the line endings
    stroke-linejoin: round; // Reduce length of the lines
}

.discountLabels {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    // transition for hiding the discount labels
    transition: max-height 0.3s ease-out, opacity 0.4s ease;
}

.discountLabelsVisible {
    max-height: 300px;
    opacity: 1;
    // transition for showing the discount labels
    transition: max-height 0.3s ease-in, opacity 0.3s ease;
}
