$env: production;
@import '@/styles/_component-base.styles.scss';

.container {
    text-align: left;
    font-size: set-fontSize(24);
    line-height: 32px;
}

.finalPriceContainer {
    display: flex;
    align-items: center;
}

.finalPrice {
    font-weight: normal;

}

.strikethroughPrice {
    color: color(text, recede);
    font-weight: 300;
    text-decoration: line-through;
    margin-right: spacer(sm);
}

.badge {
    margin-left: 1rem;
}
