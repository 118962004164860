$env: production;
@import '@/styles/_component-base.styles.scss';

.buystackTooling {
    transition: opacity 1s linear;;
    opacity: 0;
    height: 0;

    &.isvisible {
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: spacer(2xl) 0;
        min-height: 90px;
        opacity: 1;
        padding: spacer(lg) 0;
        transition: opacity 1s linear;
        gap: spacer(base);

        @include breakpoint(lg) {
            padding: spacer(lg) spacer(base);
        }
    }

    // If the test wins and the video renders in bit, this class is not needed
    &.onlyShowVideo {
        height: auto;
        margin: 0;
        padding: 0;
    }
}

.defaultBackground {
    background-color: #F5F6F6;
}

.whiteBackground {
    background-color: color(contrast, white);
}

.quizButton {
    background-color: color(accent, blue);
    border-radius: 2px;
    border: 0;
    font-size: set-fontSize(16);
    font-weight: 400;
    line-height: 20px;
    margin: auto 0;
    min-height: 31px;
    padding: spacer(xs);
    min-width: 165px;

    @include breakpoint(lg) {
        min-width: 165px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: color(accent, blue);
    }
}

.watchVideo {
    font-size: set-fontSize(16);
    font-weight: 400;
    margin: auto 0;

    // If the test wins and the video renders in bit, this class is not needed
    &.onlyShowVideo {
        margin: 0;
    }
}

.video {
    margin-top: spacer(xl);
}