$env: production;
@import '@/styles/_component-base.styles.scss';
// This modify the toggles from each product
.bundleSelectors {
    div[type="button"] {
        min-height: 40px;
    }
}

.wrapper {
    text-align: left;

    h2 {
        @extend .t-heading3; // this is intentional - semantically h2, but styled like h3
        font-family: 'Source Serif Pro';
        font-weight: normal;
        letter-spacing: .25px;
        line-height: 25px;
    }
}

.sharedProductSelectors {
    span[data-style-selector="productLabel"] {
        display: none;
    }
}

.sharedSelectionHeader {
    display: flex;
    justify-content: space-between;
    font-family: 'Source Serif Pro';
    font-size: set-fontSize(20);
    font-weight: normal;
    line-height: 25px;
    letter-spacing: .25px;
    margin-bottom: spacer(xs);
    margin-top: spacer(2xl);
}

.productWrapper {
    background-color: color(contrast, 2);
    border: 1px solid color(contrast, 3);
    margin-bottom: spacer(base);
    padding: spacer(md) spacer(base) 0 spacer(base);
    transition: all 300ms ease-in-out;

    &.shared {
        background-color: unset;
    }

    &:hover {
        border-color: color(text, hover);
    }
}

.productHeader {
    display: flex;
    gap: spacer(xs);
    overflow: hidden;
    padding-bottom: spacer(base);
    font-weight: normal;
    cursor: pointer;

    button {
        cursor: pointer;
    }
}

.productImage {
    max-height: 95px;
    font-size: set-fontSize(12); // for alt tag text
}

.productCopyWrapper {
    h3 {
        @extend .t-heading3;
        margin-bottom: spacer(2xs);
        padding-right: spacer(sm); // ensures text wraps before overlapping Edit button
    }
}

.attributesWrapper {
    display: flex;
    flex-direction: row;
    gap: spacer(lg);
    font-weight: normal;

    // Toggle default values (shown)
    // Max-height must be large enough to accommodate the largest attribute list
    max-height: 75px;
    overflow: hidden;
    opacity: 1;
    transition: all 800ms ease-in;

    .attributeValue {
        font-weight: 300;
    }

    &.wrapped {
        margin-top: -(spacer(sm));
        margin-bottom: spacer(sm);
    }
}

.productBody {
    // Toggle default values (collapsed)
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: all 500ms ease-out;
    font-size: set-fontSize(16);
    padding-left: spacer(2xs);

    ul {
        list-style: disc;
        margin-left: spacer(base);
    }
}

.productDescription {
    font-size: set-fontSize(16);
    padding-left: spacer(2xs);
    opacity: 1;
    max-height: 130px;
    font-family: 'Source Sans Pro';
    transition: all 400ms ease-in;
    line-height: 24px;

    ul {
        list-style: disc;
        margin-left: spacer(base);
    }
}

.productDescriptionMobile {
    margin-bottom: spacer(md);
}

.productDescription-hidden {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 500ms ease-out;
}

.numberedCircle {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    left: 4px;

    font-size: set-fontSize(14);
    font-weight: 400;
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: color(darkContrast, 2);
    color: color(contrast, white);
}

.awardSprite {
    width: 50px;
    height: 18px;
    margin-bottom: -3px;
}

.pdpLink {
    vertical-align: middle;
    margin-left: spacer(2xs);

    .linkSprite {
        width: 14px;
        height: 14px;
    }
}

.toggleButton {
    position: absolute;
    top: 5px;
    right: 0;
    color: color(nav, base);
}

.chevron {
    stroke-width: 1.5px;
    stroke: color(nav, base);
}

.expanded {
    background-color: inherit;

    .productBody {
        // Max-height must be large enough to accommodate the largest attribute list
        max-height: 500px;
        opacity: 1;
        transition: all 500ms ease-in;
    }

    .chevron {
        transform: scaleY(-1);
    }

    .attributesWrapper {
        opacity: 0;
        max-height: 0px;
        transition: all 200ms ease-out;
    }
}

.itemOutOfStock {
    background-color: #fbf5ec;
    color: color(darkContrast, 3);
    font-size: set-fontSize(14);
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.71;
    padding: spacer(xs) spacer(3xl);
    text-align: center
}