$env: production;
@import '@/styles/_component-base.styles.scss';

.section__reviewsTitle {
    font-family: 'Source Serif Pro', 'Times New Roman', Times, Georgia, serif;
    font-size: 32px;
    color: #463f38;
    margin-bottom: 0;
}

@media (min-width: 576px) and (max-width: 768px) {
    .section__reviewsTitle {
        font-size: 28px;
    }
}

.yotpoReviews {
    padding: spacer(2dot5xl) 0 0;
}

.addonLinks {
    color: color(darkContrast, 3);
    font-weight: 400;
    text-align: center;
    display: flex;

    @include breakpoint(sm) {
        display: inherit;
    }

    span {
        font-size: set-fontSize(16);
        padding: 0 spacer(base)
    }

    svg {
        margin-left: spacer(xs);
        width: 14px;
        height: 14px;
    }
}
