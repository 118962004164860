$env: production;
@import '@/styles/_component-base.styles.scss';

.affiliateBadge {
    width: 52px;
    height: 52px;
    position: absolute;
    left: 0;
    top: 0;
    margin: spacer(xs) spacer(md);

    @include breakpoint(md) {
        width: 80px;
        height: 80px;
        margin: spacer(base) spacer(lg);
    }

    svg {
        width: 52px;
        height: 52px;

        @include breakpoint(md) {
            width: 80px;
            height: 80px;
        }
    }
}