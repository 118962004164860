$env: production;
@import '@/styles/_component-base.styles.scss';

.deliveryEstimate {
    display: flex;
    justify-content: center;
    gap: spacer(base);
    margin-bottom: spacer(base);

    [data-style-svgtype] {
        color: #C1A38B;
    }
}

.promt {
    &--buttonTime {
        color: color(darkContrast, 3);
        text-decoration: underline;
        cursor: pointer;
        font: inherit;
    }
}

.modal {
    height: min-content;
}

.modal__header {
    font-family: "Source Serif Pro";
    font-size: set-fontSize(20);
    font-weight: normal;
}

.modal__content {
    padding: spacer(lg);
    padding-right: spacer(md);

    @include breakpoint(md) {
        padding: spacer(2xl) spacer(2xl) spacer(md) 36px;
    }

    @include breakpoint(lg) {
        padding: spacer(2xl) spacer(3xl) spacer(2xl) 52px;
    }
}

.modal__content__list {
    display: flex;
    flex-direction: column;
}

.modal__content__list__item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: spacer(2xs);
    padding-left: spacer(md);

    &:not(:last-child) {
        padding-bottom: spacer(lg);

        // Creates the left border
        &:after {
            content: "";
            border-left: spacer(3xs) solid color(accent, text);
            display: block;
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            left: -2px;
            top: spacer(xs);
        }
    }

    // Creates a circle in the corner of the div.
    &::before {
        content: "";
        border: spacer(3xs) solid color(accent, text);
        border-radius: 25px;
        background: color(contrast, white);
        position: absolute;
        width: 10px;
        height: 10px;
        left: -6px;
        top: spacer(2xs);
    }

    > :first-child {
        font-weight: normal;
        line-height: 1;
    }

    p {
        margin-bottom: 0;
        line-height: 1.25;
    }

    b {
        font-weight: normal;
    }

    i {
        display: inline-block;
        font-style: italic;
        font-size: set-fontSize(14);
        margin-top: spacer(2xs);
    }
}

.modal__footer {
    padding: spacer(lg) 0;
    margin: 0 spacer(md);

    @include breakpoint(md) {
        padding: spacer(2xl) 0;
        margin: 0 spacer(3xl);
    }

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: unset;
    border: unset;
    border-top: 1px solid color(contrast, 4);
}

.modal__footer__copy {
    margin-bottom: 0;
    font-size: set-fontSize(14);
}