$env: production;
@import '@/styles/_component-base.styles.scss';

.title {
    font-size: set-fontSize(20);
    font-family: Source Serif Pro;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: spacer(xs) 0;
}

.bundleList {
    padding: spacer(xs) 0 0 0;
}

.bundleItemsSection {
    background-color: color(contrast, 2);
    border-left: 1px solid color(text, headingDark);
    margin-top: spacer(xl);
    padding:  spacer(base);
}

.bundleItems {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 300;
    font-family: "Source Sans Pro";
    line-height: 1.5;
}

.bundleItemsName {
    text-align: left;
    width: 174px;

    @include breakpoint(md) {
        width: auto
    }
}

.icon {
    height: 18px;
    width: 18px;
    color: color(accent, green);
}
