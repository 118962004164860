$env: production;
@import '@/styles/_component-base.styles.scss';

.bidirectionalTab {
    cursor: pointer;
}

.bidirectionalTab__sliderBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 4px;
    background-color: color(text, base);
    transition: all 500ms ease;
}