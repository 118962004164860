@import '@saatva-bits/pattern-library.styles.core/theme/saatva/settings.scss';
@import '@saatva-bits/pattern-library.styles.core/settings.styles.scss';
@import '@saatva-bits/pattern-library.styles.core/tools.styles.scss';

// make typo utils available in css modules for class extension (@extend)
@import '@saatva-bits/pattern-library.styles.core/theme/saatva/component-configs.scss';
@include makeTypoClasses($typographyTheme-custom);

@import './move-to-pl.styles.scss';
@import './move-to-pl-tools.styles.scss';

.buystack {
    [data-style-selector="productLabel__swatch"] {
        font-size: set-fontSize(16);
    }
}
