$env: production;
@import '@/styles/_component-base.styles.scss';

.scaleComfortLevel {
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
        margin: 0 auto;
        width: 500px;
    }
}

.tabList {
    background-color: color(contrast, white);
    border: solid 1px color(contrast, 3);
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    padding: spacer(3xs);

    @include breakpoint(md) {
        width: 442px;
    }

    &Scroll {
        margin: 0 auto;
    }
    
}

.tabName {
    background-color: color(contrast, white);
    font-size: set-fontSize(16);
    min-width: 106px;
    padding: spacer(2xs) spacer(2xs);
    width: 100%;
    border-radius: 4px;

    @include breakpoint(md) {
        margin: 0 spacer(3xs);
    }

    &:hover {
        background-color: color(accent, greenLight);
    }

    &--isActive {
        background-color: color(accent, green);
        color: color(state, base);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
    
        &:hover {
            background-color: color(accent, green);
        }
    }

    &--blueColor {
        background-color: color(accent, white);
    
        &:hover {
            background-color: color(contrast, 1);
        }

        &--isActive {
            background-color: color(accent, blue);
        
            &:hover {
                background-color: color(accent, blue);
                color: color(text, white);
            }
        }
    }
}

.tabContentWrapper {
    display: none;

    @include breakpoint(md) {
        min-height: 413px;
    }
}

.is-active {
    display: inline-block;
}

.tabHeading {
    font-size: set-fontSize(18);
    font-weight: normal;
    line-height: 1.44;
    margin-bottom: spacer(base);
}

.tabContent {
    font-size: set-fontSize(16);
    line-height: 1.5;

    ul {
        list-style: disc;
        margin-left: spacer(base);
        margin-bottom: spacer(base);
    }

    li {
        margin-bottom: spacer(xs);
    }
}

.comfortLevelHeading {
    font-weight: 400;
    margin-bottom: spacer(xs);
}

.comfortLevelList {
    list-style: disc;
}

.comfortMeter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -(spacer(base));

    @include breakpoint(md) {
        margin-bottom: 0;
    }
}

.comfortMeterIcon {
    width: 90%;
    margin-top: -(spacer(lg));
    height: 150px;

    @include breakpoint(sm) {
        height: 200px;
        margin-top: 0;
    }

    @include breakpoint(md) {
        height: 150px;
        margin-top: 0;
    }
}

.comfortMeterIcons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.comfortMeterSvg {
    max-width: 11%;
    margin-top: -4.9rem;

    @include breakpoint(sm) {
        margin-top: -3.9rem
    }

    @include breakpoint(md) {
        margin-top: -(spacer(3xl));
    }
}

.comfortMeterText {
    margin-top: -(spacer(5xl));
    font-size: set-fontSize(24);
    font-weight: 400;

    &.scale-3 {
        right: 24%;
    }

    &.scale-3-4 {
        right: 19%;
    }

    &.scale-4-5 {
        right: 10%;
    }

    // 0% is centered, so 5-6 doesn't require positioning

    &.scale-6,
    &.scale-5-7 {
        left: 5%;
    }

    &.scale-7-8 {
        left: 19%;
    }

    &.scale-8 {
        left: 24%;
    }
}

.comfortMeterLabels {
    display: flex;
    justify-content: space-between;
    width: 96%;

    >p {
        margin-top: -3.6rem;
        font-weight: 400;
        margin-bottom: 0;

        @include breakpoint(sm) {
            margin-top: -(spacer(3xl));
        }
    }
}
