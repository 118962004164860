$env: production;
@import '@/styles/_component-base.styles.scss';

// Panel dimensions
$panel-height: 250px;
$panel-border-radius: 12px;
$panel-padding: 16px;
$panel-side-padding: 20px;

// Animation timings
$animation-duration: 100ms;
$animation-duration-closing: 500ms;

// Z-index layers
$z-index-overlay: 5000;
$z-index-panel: 5001;

// Colors
$favorite-button-bg: #e2c492;
$favorite-button-hover: lighten($favorite-button-bg, 10%);
$slide-bar-bg: color(darkContrast, 3);

// Dimensions
$slide-bar-width: 50px;
$slide-bar-height: 4px;
$favorite-icon-height: 44px;

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: color(text, heading);
    opacity: 0.8;
    z-index: $z-index-overlay;
    transition: opacity $animation-duration ease-out, backdrop-filter $animation-duration ease-out;

    &.overlayClosing {
        opacity: 0;
        backdrop-filter: blur(0px);
    }
}

.addToFavoritesPanel {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: $z-index-panel;
    border-radius: $panel-border-radius $panel-border-radius 0 0;
    box-shadow: 0 -6px 10px 0 rgba(0, 0, 0, 0.25);
    background-image: linear-gradient(to bottom, #000, color(darkContrast, 3));
    height: fit-content;
    padding: $panel-padding $panel-side-padding;
    padding-bottom: calc($panel-padding + $panel-height);
    margin-bottom: -$panel-height;
    transition: transform $animation-duration ease-out;
    transform: translateY(0);

    &.closing {
        transition: transform $animation-duration-closing ease-out;
    }
}

.draggablePanel {
    touch-action: none; // Prevent scrolling while dragging
    will-change: transform; // Optimize for animations
    user-select: none; // Prevent text selection while dragging
}

.favoriteIcon {
    width: 100%;
    max-width: 450px;
    height: $favorite-icon-height;
    background-color: $favorite-button-bg;
    color: #000;
    font-size: set-fontSize(16);
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    margin: 0 auto;
    padding: 0;
    display: flex; // Changed to flex to enable vertical centering
    align-items: center; // Center content vertically
    justify-content: center; // Center content horizontally
    
    &:hover,
    &:active {
        background-color: $favorite-button-hover;
    }

    &:active {
        -webkit-tap-highlight-color: transparent;
        -moz-tap-highlight-color: transparent;
        -ms-tap-highlight-color: transparent;
    }
    
    &::before {
        content: "Add to Favorites";
        margin-right: 8px;
    }
}

.slideBar {
    width: $slide-bar-width;
    height: $slide-bar-height;
    background-color: $slide-bar-bg;
    border-radius: 10px;
    margin: 0 auto spacer(sm);
    cursor: grab;
}

.text {
    text-align: center;
    font-size: set-fontSize(14);
    line-height: 22px;
    color: white;
    margin: 12px auto 16px auto;

    a {
        color: white;
        font-weight: 400;
    }
}

.buttonText {
    font-size: set-fontSize(16);
    margin: auto 8px;
    color: black;
}
