$env: production;
@import '@/styles/_component-base.styles.scss';

.option {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: spacer(sm) spacer(sm);
    margin-bottom: spacer(md);
  
    border: solid 1px color(state, hover);
    border-radius: 10px;
    transition: all 200ms ease;
  
    @include breakpoint(md) {
      padding: spacer(sm) spacer(2xl);
    }
}
  
.selectable {
    cursor: pointer;
    &:hover {
        border: solid 1px color(accent, base);
    }
}

.nonInteractable {
    &:hover {
        border: solid 1px color(state, hover);
    }
}
  
.visuallyHidden {
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
}
  
.comfortSelection {
    cursor: auto; /* was pointer, but this modal only used auto with a second class */
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}
    
.description {
    flex: 1 100%;
    margin-top: spacer(sm);

    @include breakpoint(md) {
        margin-top: 0;
        flex: 1;
    }
}
  
.label {
    margin-bottom: 0;
    @extend .t-heading4;
    color: color(text, heading);
}

.level {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-left: 1rem;

    font-size: set-fontSize(20);
    font-weight: 400;
    background: color(darkContrast, 2);
    color: color(contrast, white);
    border-radius: 50%;
    transition: all 200ms ease;

    @include breakpoint(md) {
        width: spacer(4xl);
        height: spacer(4xl);
        margin-right: spacer(2xl);
        margin-left: 0;
        font-size: set-fontSize(24);
    }
}
  
.flipImage {
    display: block;
    margin: 0 auto;
}

.flipLabel {
    display: block;
    font-size: set-fontSize(14);
    font-weight: $fontWeight-base;
    text-align: center;
}
